import { LogoSvg } from '@/common/svgs';
import ExpandAndRetractX from '../../common/utils/expandAndRetractX';
import FadeIn from '../../common/utils/fadeIn';
import SlideUp from '../../common/utils/slideUp';
import Background from './background';
import Intro from './intro';
import logoSmall from '@/public/logo_small.svg';
import Image from 'next/image';
import { Homepage } from '@/typings/types';

type HeaderWrapperProps = {
  header: Homepage['header'];
};

const HeaderWrapper = ({ header }: HeaderWrapperProps) => {
  return (
    <header className={'w-full'}>
      <FadeIn
        delay
        className={'absolute top-0 z-20 hidden h-[102px] w-full bg-top-border lg:block'}
      ></FadeIn>
      <div
        aria-hidden={'false'}
        className={'absolute top-0 z-20 block h-[102px] w-full bg-top-border lg:hidden'}
      ></div>
      <div className={'hidden lg:block'}>
        <ExpandAndRetractX
          className={'top absolute z-10 w-full bg-chocolate lg:h-screen'}
          aria-hidden={'true'}
        ></ExpandAndRetractX>
      </div>
      <div className={'flex flex-col bg-chocolate lg:h-screen lg:flex-row'}>
        <div className={'relative flex h-[50vh] w-full items-start lg:h-screen xl:w-[49%]'}>
          <div className={'absolute -bottom-28 z-40 flex w-full items-center justify-center'}>
            <Image priority src={logoSmall} alt={'logo'} />
          </div>
          <Background priority background={header.background} />
        </div>
        <div
          className={
            'relative z-20 mx-auto mt-48 flex h-full w-10/12 flex-col items-center justify-center lg:justify-start xl:mx-0 xl:mt-0 xl:w-[405px] xl:items-start xl:justify-center xl:pl-32 3xl:w-[51%] 3xl:pl-64'
          }
        >
          <SlideUp
            yOffset={20}
            delay
            delayDuration={550}
            className={
              'mb-14 hidden w-full max-w-[405px] sm:w-[405px] lg:block 3xl:w-7/12 3xl:max-w-7xl'
            }
          >
            <LogoSvg />
          </SlideUp>
          <div
            className={
              'mb-14 block w-full max-w-[405px] sm:w-[405px] lg:hidden 3xl:w-7/12 3xl:max-w-7xl'
            }
          >
            <LogoSvg />
          </div>
          <SlideUp
            yOffset={5}
            duration={800}
            delay
            delayDuration={700}
            className={
              'hidden max-w-[405px] pb-24 sm:w-[405px] lg:block lg:pb-0 3xl:w-7/12 3xl:max-w-7xl'
            }
          >
            <Intro intro={header.intro} />
          </SlideUp>
          <div
            className={
              'block max-w-[405px] pb-24 sm:w-[405px] lg:hidden lg:pb-0 3xl:w-7/12 3xl:max-w-7xl'
            }
          >
            <Intro intro={header.intro} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderWrapper;
