import { animated, useSpring } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

type IProps = {
  className?: string;
  duration?: number;
  children?: React.ReactNode;
};

const ExpandAndRetractX = ({ className, duration, children }: IProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.8,
    triggerOnce: true,
  });
  const expandAndRetract = useSpring({
    from: { transform: 'scaleX(1)' },
    to: {
      transform: inView ? `scaleX(0)` : 'scaleX(1)',
      transformOrigin: '100% 0%',
    },
    config: {
      duration: duration ?? 1250,
    },
  });
  // use a custom tag for the animated dom
  return (
    <animated.div ref={ref} className={className} style={expandAndRetract}>
      {children}
    </animated.div>
  );
};

export default ExpandAndRetractX;
