import HeaderWrapper from './header/headerWrapper';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { Homepage } from '@/typings/types';
import PageFooter from '@/common/pageFooter';

const PresentationWrapper = dynamic(() => import('./presentation/presentationWrapper'));
const Mof = dynamic(() => import('./presentation/mof'));
const SkillWrapper = dynamic(() => import('./skill/skillWrapper'));
const ShowcaseWrapper = dynamic(() => import('./showcase/showcaseWrapper'));

type HomepageWrapperProps = {
  homepage: Homepage;
};

const HomepageWrapper = ({ homepage }: HomepageWrapperProps) => {
  return (
    <Fragment>
      <HeaderWrapper header={homepage.header} />
      <div className={'bg-fadeWhite flex w-11/12 flex-col items-center justify-center'}>
        <PresentationWrapper presentation={homepage.presentation} />
      </div>
      <Mof />
      {homepage?.skill && <SkillWrapper skill={homepage?.skill} />}
      <ShowcaseWrapper showcase={homepage.showcase} />
      <PageFooter homepage={homepage} />
    </Fragment>
  );
};

export default HomepageWrapper;
