import Image from 'next/image';
import { Homepage } from '@/typings/types';

type IProps = {
  background: Homepage['header']['background'];
  priority?: boolean;
};

const Background = ({ background, priority }: IProps) => {
  const { url, alternativeText, placeholder } = background?.data?.attributes ?? {};
  return (
    url && (
      <Image
        src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${url}`}
        alt={alternativeText ?? ''}
        className={'relative z-10 object-cover lg:z-0'}
        priority={priority}
        fill
        placeholder={placeholder ? 'blur' : 'empty'}
        blurDataURL={placeholder ?? ''}
      />
    )
  );
};

export default Background;
