import Seo from '../components/common/utils/seo';
import HomepageWrapper from '../components/homepage/homepageWrapper';
import type { GetStaticProps, NextPage } from 'next';
import { getHomepageData } from '../api/homepageQueries';
import { Homepage } from '@/typings/types';

type IPageProps = {
  homepage: Homepage;
};

const Home: NextPage<IPageProps> = ({ homepage }) => {
  return (
    <main>
      {homepage?.seo && <Seo seo={homepage?.seo} />}
      <HomepageWrapper homepage={homepage} />
    </main>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async () => {
  const { homepage } = await getHomepageData();
  return {
    props: {
      homepage: homepage?.data?.attributes,
    },
    revalidate: 1,
  };
};
